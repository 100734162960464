import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DataService from "../../data-service";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useBreadcrumb } from '../../breadcrumb-context';
import moment from 'moment';
import { useMsal } from "@azure/msal-react";

export default function Tickets() {


  const { instance, accounts } = useMsal();
  const dataService = new DataService(instance, accounts[0]);

  const { setBreadcrumbs } = useBreadcrumb();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const currentDate = moment().endOf('day');  // Current date at 23:59:59.999

  const initialStartDate = searchParams.get('start') ? moment(searchParams.get('start')) : moment().subtract(1, 'days').startOf('day');
  const initialEndDate = searchParams.get('end') ? moment(searchParams.get('end')).endOf('day') : currentDate;
  
  const [startDate, setStartDate] = useState(initialStartDate.toDate());
  const [endDate, setEndDate] = useState(initialEndDate.toDate());
  const [searching, setSearching] = useState(false);

  const columns = [
    {
      field: 'ticketNumber', headerName: 'Ticket Number', width: 200, filterable: true, sortable: true,  flex: 1, renderCell: (params) => (
        <Link to={`/tickets/${params.row.id}`}>{params.value}</Link>
      ),
    },
    { field: 'ticketStatus', headerName: 'Ticket Status', width: 150, filterable: true },
    { field: 'truckName', headerName: 'Truck Name', width: 150, filterable: true },
    { field: 'ticketInsertUserId', headerName: 'Driver', width: 200, filterable: true, type: 'string' },
    { field: 'ticketInsertDate', headerName: 'Insert Date', width: 200, filterable: true, type: 'date' ,
    renderCell: (params) => moment(params.value).format("YYYY-MM-DD HH:mm:ss")},
    { field: 'producerName', headerName: 'Producer Name', width: 200, filterable: true, sortable: true, flex: 1 },
    { field: 'croppProducerId', headerName: 'Cropp Producer ID', width: 200, filterable: true },
  ];


  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Set the start and end dates in the query string if they are not already present
    if (!searchParams.get('start') || !searchParams.get('end')) {
      navigate(`${location.pathname}?start=${moment(startDate).format('YYYY-MM-DD')}&end=${moment(endDate).format('YYYY-MM-DD')}`);
    }
    fetchTickets(startDate, endDate);
  }, []);

  async function fetchTickets(startDate, endDate) {
    try {
      setSearching(true);
      const data = await dataService.getTickets(startDate, endDate);
      const rows = data.map((ticket) => ({
        id: ticket.ticketId,
        ticketNumber: ticket.ticketNumber,
        ticketStatus: ticket.ticketStatus,
        truckName: ticket.truck.truckName,
        ticketInsertUserId: ticket.ticketInsertUserId,
        ticketInsertDate: moment(ticket.ticketInsertDate).toDate(),
        producerName: ticket.producer.producerName,
        croppProducerId: ticket.producer.croppProducerId,
        isCompleted: ticket.ticketStatus === 'C',
      }));
      setTickets(rows);
      setSearching(false);
      setBreadcrumbs([
        { label: 'Tickets', path: '/tickets' },
      ]);

    } catch (err) {
      setError(err.message);
      setSearching(false);
    }
  }

  function onSearch() {
    if (moment(endDate).isBefore(startDate)) {
      setError('endDate must be greater than or equal to startDate.');
      return;
    }
    
    if (moment(endDate).diff(startDate, 'days') > 7) {
      setError('The date range should not exceed 7 days.');
      return;
    }

    navigate(`${location.pathname}?start=${moment(startDate).format('YYYY-MM-DD')}&end=${moment(endDate).format('YYYY-MM-DD')}`);

    setError('');
    fetchTickets(startDate, endDate);
  }


  return (
    <Box sx={{ height: '100%', width: '100%', p: 2 }}>

      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2} display="flex" flexDirection="column" justifyContent="center">
          <TextField
            label="Start Date"
            type="date"
            id="startDate"
            value={moment(startDate).format('YYYY-MM-DD')}
            onChange={(e) => setStartDate(moment(e.target.value).toDate())}
          />
        </Box>
        <Box mr={2} display="flex" flexDirection="column" justifyContent="center">
          <TextField
            label="End Date"
            type="date"
            id="endDate"
            value={moment(endDate).format('YYYY-MM-DD')}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Button variant="contained" onClick={onSearch}>
            Search
          </Button>
        </Box>
      </Box>


      {error && <Typography color="error">{error}</Typography>}
      <Box sx={{ height: '100%', width: '100%' }}>

        {tickets.length ? (
          <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
              rows={tickets}
              columns={columns}
              getRowClassName={(params) => {
                return params.row.isCompleted ? 'completedRow' : '';
              }}
              sx={{
                '& .completedRow': {
                  backgroundColor: 'lightgreen',
                },
                height: 400, // Consider adjusting this value based on your layout needs
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'ticketInsertDate', sort: 'desc' }],
                },
              }}
              rowHeight={30} // Decrease the row height
              slots={{ toolbar: GridToolbar }}
            />
          </Box>
        ) : (
            searching ? ( <Typography>Searching for tickets...</Typography> ) : ( <Typography>No tickets found.</Typography> )
        )}
      </Box>
    </Box>
  );
}
