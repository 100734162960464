import React from 'react';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from '@mui/material';
  

const SummaryCard = ({ summaryData }) => {

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 3, // Ensures that at least 3 decimal places are shown
        maximumFractionDigits: 3, // Ensures that no more than 3 decimal places are shown
    });

    return (
        <Card>
          <CardContent style={{ padding: '6px' }}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pay Price</TableCell>
                      <TableCell>Total Dozen</TableCell>
                      <TableCell>Net Weight</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {summaryData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{currencyFormatter.format(item.payPrice)}</TableCell>
                        <TableCell>{item.totalDozen.toFixed(2)}</TableCell>
                        <TableCell>{item.netWeight.toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </CardContent>
        </Card>


      );
      


};

export default SummaryCard;
