import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const API_URL = import.meta.env.VITE_API_URL;

class DataService {
  constructor(msalInstance, account) {
    this.msalInstance = msalInstance;
    this.account = account;
  }

  async getToken() {

    const request = {
      scopes: ["api://cropp.eggcollection.api/.default"],
      account: this.account
    };

    try {
      const response = await this.msalInstance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interacting with the user
        const response = await this.msalInstance.acquireTokenPopup(request);
        return response.accessToken;
      }
    }

  }

  async createPurchaseOrders(ticket) {
    try {
        const token = await this.getToken();
        const response = await axios.post(`${API_URL}/ticket/create-purchase-orders`, ticket, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
}


  async getTickets(startDate, endDate) {
    try {
      const token = await this.getToken();
      const response = await axios.get(`${API_URL}/ticket/by-date?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async syncProducers() {
    try {
      const token = await this.getToken();
      const response = await axios.post(
        `${API_URL}/producer/sync`,
        {}, // Assuming no payload for the POST request
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  

  async getAll(entity) {
    const token = await this.getToken();
    return axios.get(`${API_URL}/${entity}`,{
      headers: {
        'Authorization': `Bearer ${token}`
    }
    })
    .then(response => 
      response.data
    );
  }

  async get(entity,id) {
    const token = await this.getToken();
    return axios.get(`${API_URL}/${entity}/${id}`,{
      headers: {
        'Authorization': `Bearer ${token}`
    }
    })
    .then(response => response.data);
  }

  async create(entity,data) {
    const token = await this.getToken();
    return axios.post(`${API_URL}/${entity}`, data,{
      headers: {
        'Authorization': `Bearer ${token}`
    }
    });
  }

  async update(entity,data) {
    const token = await this.getToken();
    return axios.put(`${API_URL}/${entity}`, data,{
      headers: {
        'Authorization': `Bearer ${token}`
    }
    });
  }

  async delete(entity,id) {
    const token = await this.getToken();
    return axios.delete(`${API_URL}/${entity}/${id}`,{
      headers: {
        'Authorization': `Bearer ${token}`
    }
    });
  }

}

export default DataService;