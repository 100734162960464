import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DataService from "../../data-service";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import SummaryCard from './summary-card';
import { Select, MenuItem, FormControl, InputLabel, Button, Box,  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Backdrop, CircularProgress } from '@mui/material';
import { useBreadcrumb } from '../../breadcrumb-context';
import ErrorBlock from '../../error-block';
import ProducerDetailsCard from './producer-details-card';
import { ArrowBack, Send } from '@mui/icons-material';
import { useMsal } from "@azure/msal-react";

function Ticket() {

    const { instance, accounts } = useMsal();
    const dataService = new DataService(instance, accounts[0]);

    const { ticketId } = useParams();
    const { setBreadcrumbs } = useBreadcrumb();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openConfirmDialogForSetToPending, setOpenConfirmDialogForSetToPending] = useState(false);
    const [ticket, setTicket] = useState(null);
    const [producers, setProducers] = useState([]);
    const [selectedProducer, setSelectedProducer] = useState(null);
    const [plants, setPlants] = useState([]);
    const [trucks, setTrucks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newPrice, setNewPrice] = useState('');
    const [errorMessages, setErrorMessages] = useState(null);
    const [isErpInfoModalOpen, setIsErpInfoModalOpen] = useState(false);

    const clearError = () => {
        setErrorMessages(null);
    };

    const saveTicketFlag = useRef(false);
    const isDisabled = ticket?.ticketStatus === 'C';

    useEffect(() => {
        async function fetchData() {

            const ticketData = await dataService.get("ticket", ticketId);
            updateTicket(ticketData, false);

            setBreadcrumbs([
                { label: 'Tickets', path: '/tickets' },
                { label: ticketData.ticketNumber, path: ticketId },
            ]);

            const producerData = await dataService.getAll('producer');
            const sortedProducerData = producerData.sort((a, b) => a.producerName.localeCompare(b.producerName));
            setProducers(sortedProducerData);

            const producer = producers.find((p) => p.producerId == ticket.producerId);
            setSelectedProducer(producer);

            const truckData = await dataService.getAll('truck');
            const sortedTruckData = truckData.sort((a, b) => {
                if ((a.status === "A") === (b.status === "A")) {
                    return a.truckDescription.localeCompare(b.truckDescription);
                }
                return a.status === "A" ? -1 : 1;
            });
            setTrucks(sortedTruckData);
            const plantData = await dataService.getAll('plant');
            setPlants(plantData);
            setLoading(false);
        }
        fetchData();
    }, [ticketId]);


    useEffect(() => {
        const saveTicket = async () => {
            await save();
            saveTicketFlag.current = false; // Reset the flag after saving
        };

        if (ticket && saveTicketFlag.current) {
            saveTicket();
        }
    }, [ticket]);


    const updateTicket = (newTicket, shouldSave = false) => {
        setTicket(newTicket);

        // Set a flag in a ref to determine whether the updated state should be saved
        saveTicketFlag.current = shouldSave;
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
    
        if (name === "producerId") {
            const producer = producers.find((p) => p.producerId == value);
            setSelectedProducer(producer);
            // update the ticket's producerId and barnNumber fields when name is 'producerId'
            updateTicket({ ...ticket, [name]: value, barnNumber: producer.barnNumber }, true);
        } else {
            // else just update the field with name passed into the change event
            updateTicket({ ...ticket, [name]: value }, true);
        }
    };
    
    const applyPriceToAll = () => {
        const selectedRows = apiRef.current.getSelectedRows();

        if (ticket && ticket.ticketDetails) {
            const updatedTicketDetails = ticket.ticketDetails.map((detail) => {
                if (selectedRows.has(detail.ticketDetailId)) {
                    return {
                        ...detail,
                        payPriceAmount: parseFloat(newPrice),
                    };
                }
                return detail;
            });

            for (const row of selectedRows) {
                apiRef.current.selectRow(row[0], false, false);
            }
            setNewPrice('');
            updateTicket({ ...ticket, ticketDetails: updatedTicketDetails }, true);

        }
    };

    const save = async () => {
        try {
            setOpenBackdrop(true); // Open the backdrop
    
            const response = await dataService.update('ticket', ticket);
            const serviceOperationResult = response;
    
            if (serviceOperationResult.validationData?.valid === false) {
                setErrorMessages(serviceOperationResult.validationData);
            } else {
                updateTicket(serviceOperationResult.data, false);
                setErrorMessages(null);
                toast.success('Ticket updated.', { autoClose: 2000 });
            }
        } catch (error) {
            console.error('Error updating ticket:', error);
        } finally {
            setOpenBackdrop(false); // Close the backdrop
        }
    };
    

    const handleSend = async (event) => {
        event.preventDefault();
        try {

            setOpenBackdrop(true); // Open the backdrop

            const response = await dataService.createPurchaseOrders(ticket);
            const serviceOperationResult = response;

            if (serviceOperationResult.validationData?.valid === false) {
                setErrorMessages(serviceOperationResult.validationData);
            } else {
                updateTicket(serviceOperationResult.data, false);
                setErrorMessages(null);
                toast.success('Ticket sent.', { autoClose: 2000 });
            }
        } catch (error) {
            if (error.response?.data?.valid === false) {
                setErrorMessages(error.response.data);
            } else {
                setErrorMessages(error);
            }
        } finally { 
            setOpenBackdrop(false); // Close the backdrop
        }
    };

    const handleSetToPending = (event) => {
        event.preventDefault();
        setOpenConfirmDialogForSetToPending(true);
    };

    const handleConfirmForSetToPending = () => {
        if (ticket) {
            updateTicket({
                ...ticket,
                ticketStatus: "P",
            }, true);
        }
        setOpenConfirmDialogForSetToPending(false);
    };
    
    const handleCancelForSetToPending = () => {
        setOpenConfirmDialogForSetToPending(false);
    };
    
    
    const handleCancel = () => {
        navigate(-1);
    };

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 3, // Ensures that at least 3 decimal places are shown
        maximumFractionDigits: 3, // Ensures that no more than 3 decimal places are shown
    });

    const columns = [
        { field: 'eggTypeName', headerName: 'Egg Type', width: 75, valueGetter: (params) => params.row.eggType.eggTypeName, },
        {
            field: 'purchaseOrderId',
            headerName: 'PO #',
            width: 125,
            renderCell: (params) => {
                const purchaseOrderId = params.value;
                const baseLink = import.meta.env.VITE_SAP_PO_LINK;
                const url = baseLink.replace('@purchaseOrderId', purchaseOrderId);
                return (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {purchaseOrderId}
                    </a>
                );
            },
        },
        { field: 'grossWeight', headerName: 'Gross Weight', width: 125, valueFormatter: (params) => parseFloat(params.value).toFixed(2), },
        { field: 'stackCount', headerName: 'Stack Count', width: 125 },
        { field: 'netWeight', headerName: 'Net Weight', width: 125, valueFormatter: (params) => parseFloat(params.value).toFixed(2), },
        { field: 'totalDozen', headerName: 'Dozens', width: 125 },
        { field: 'rackName', headerName: 'Pallet', width: 125, valueGetter: (params) => params.row.rack.rackDescription, },
        { field: 'redTagFlag', headerName: 'Red Tag', width: 125 },
        { field: 'jumboFlag', headerName: 'Jumbo', width: 125 },
        {
            field: 'payPriceAmount',
            headerName: 'Pay Price',
            width: 150,
            editable: true,
            valueFormatter: (params) => currencyFormatter.format(params.value),
        }
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };



    const groupedTicketDetails = ticket.ticketDetails.reduce((acc, ticketDetail) => {
        const payPrice = ticketDetail.payPriceAmount;
        const existingGroup = acc.find((group) => group.payPrice === payPrice);

        if (existingGroup) {
            existingGroup.totalDozen += ticketDetail.totalDozen;
            existingGroup.netWeight += ticketDetail.netWeight;
        } else {
            acc.push({
                payPrice,
                totalDozen: ticketDetail.totalDozen,
                netWeight: ticketDetail.netWeight,
            });
        }

        return acc;
    }, []);


    return (
        <Box>

            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
            >
            <CircularProgress color="inherit" />
            </Backdrop>


            <Box>
                <ErrorBlock error={errorMessages} onClearError={clearError} />
            </Box>



            <form>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="mb-0 d-inline">
                            Ticket # {ticket.ticketNumber} -{' '}
                            {ticket.ticketStatus === 'C'
                                ? 'Complete'
                                : ticket.ticketStatus === 'P'
                                    ? 'Pending'
                                    : 'Failed'}
                        </h5>
                        {
                            ticket.ticketStatus === 'C' && (
                                <a href="#" className="ms-2 d-inline" onClick={handleSetToPending}>
                                    Set to Pending
                                </a>
                            )
                        }
                        <Dialog
                        open={openConfirmDialogForSetToPending}
                        onClose={handleCancelForSetToPending}
                        >
                            <DialogTitle>Set to Pending</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Resending this ticket will create another purchase order in ERP system. Clean up in the ERP system may be required. Are you sure you want to set this ticket to pending?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCancelForSetToPending} color="primary">
                                Cancel
                                </Button>
                                <Button onClick={handleConfirmForSetToPending} color="primary" autoFocus>
                                Change status to Pending
                                </Button>
                            </DialogActions>
                        </Dialog>



                        <Dialog
                        open={isErpInfoModalOpen}
                        onClose={() => setIsErpInfoModalOpen(false)}
                        aria-labelledby="erp-info-title"
                        maxWidth="md" // Adjusts the maximum width of the dialog to accommodate JSON content better
                        >
                        <DialogTitle id="erp-info-title">ERP Submission Information</DialogTitle>
                        <DialogContent>
                            <DialogContentText component="div">
                            <strong>Status:</strong> {ticket?.submitToErpStatus}<br/>
                            <strong>Date:</strong> {ticket.submitToErpDate && new Intl.DateTimeFormat('default', {
  year: 'numeric', month: '2-digit', day: '2-digit', 
  hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
}).format(new Date(ticket.submitToErpDate))}<br/>
                            <strong>User ID:</strong> {ticket?.submitToErpUserId}<br/>
                            <strong>Payload:</strong>
                            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all', fontFamily: 'monospace' }}>
                                {ticket?.submitToErpPayload ? JSON.stringify(JSON.parse(ticket.submitToErpPayload), null, 2) : 'N/A'}
                            </pre>
                            <strong>Response:</strong>
                            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all', fontFamily: 'monospace' }}>
                                {ticket?.submitToErpResponse ? JSON.stringify(JSON.parse(ticket.submitToErpResponse), null, 2) : 'N/A'}
                            </pre>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsErpInfoModalOpen(false)}>Close</Button>
                        </DialogActions>
                        </Dialog>


                        <div className="d-flex">
                            <div className="small fst-italic me-2">
                                Created By {ticket.ticketInsertUserId} At {ticket.ticketInsertDate && new Intl.DateTimeFormat('default', {
  year: 'numeric', month: '2-digit', day: '2-digit', 
  hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
}).format(new Date(ticket.ticketInsertDate))} |
                            </div>
                            <div className="small fst-italic mb-0">
                                Modified By {ticket.appUpdateUserId} At {ticket.appUpdateDate && new Intl.DateTimeFormat('default', {
  year: 'numeric', month: '2-digit', day: '2-digit', 
  hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
}).format(new Date(ticket.appUpdateDate))}
                            </div>
                            {
                            ticket?.submitToErpStatus && (
                                <>
                                    <div className="small fst-italic mb-0" style={{ marginLeft: '5px', marginRight: '5px' }}>
                                      |
                                    </div>
                                    <a href="#" className="small fst-italic mb-0"
                                    onClick={() => setIsErpInfoModalOpen(true)}
                                    >
                                    Submitted By {ticket.submitToErpUserId} At {ticket.submitToErpDate && new Intl.DateTimeFormat('default', {
  year: 'numeric', month: '2-digit', day: '2-digit', 
  hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
}).format(new Date(ticket.submitToErpDate))}

                                    </a>
                                </>

                            )
                            }
                        </div>
                    </div>
                    <div>
                        <Button variant='contained' startIcon={<ArrowBack />} className="me-2" onClick={handleCancel}>
                            Back
                        </Button>
                        <Button disabled={isDisabled} variant='contained' endIcon={<Send />} onClick={handleSend}>
                            Complete And Send to ERP
                        </Button>
                    </div>
                </div>


                <div className="row mt-3">

                    <div className="col">
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="producerId-label">Producer</InputLabel>
                            <Select
                                disabled={isDisabled}
                                id="producerId"
                                name="producerId"
                                label="Producer"
                                labelId="producerId-label"
                                value={ticket.producerId}
                                onChange={handleChange}
                            >
                                {producers.map((producer) => (
                                    <MenuItem key={producer.producerId} value={producer.producerId}>
                                        {producer.producerName} - {producer.croppProducerId} - (Barn # {producer.barnNumber})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col">
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="plantId-label">Plant</InputLabel>
                            <Select
                                disabled={isDisabled}
                                id="plantId"
                                name="plantId"
                                label="Plant"
                                labelId="plantId-label"
                                value={ticket.plantId}
                                onChange={handleChange}
                            >
                                {plants.map((plant) => (
                                    <MenuItem key={plant.plantId} value={plant.plantId}>
                                        {plant.plantDescription} ({plant.plantErpId})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col">


                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="truckId-label">Truck</InputLabel>
                            <Select
                                disabled={isDisabled}
                                id="truckId"
                                name="truckId"
                                label="Truck"
                                labelId="truckId-label"
                                value={ticket.truckId}
                                onChange={handleChange}
                            >
                                {trucks.flatMap((truck, index) => {
                                    const isInactiveLabel = truck.status !== "A" && (index === 0 || trucks[index - 1].status === "A");
                                    if (isInactiveLabel) {
                                        return [
                                            <MenuItem key={`inactive-label-${index}`} disabled>
                                                {" "}
                                                - Inactive -
                                            </MenuItem>,
                                            <MenuItem key={truck.truckId} value={truck.truckId} disabled={truck.status !== "A"}>
                                                {truck.truckDescription}
                                            </MenuItem>,
                                        ];
                                    } else {
                                        return (
                                            <MenuItem key={truck.truckId} value={truck.truckId} disabled={truck.status !== "A"}>
                                                {truck.truckDescription}
                                            </MenuItem>
                                        );
                                    }
                                })}
                            </Select>
                        </FormControl>




                    </div>
                </div>
            </form>
            <div className="row align-items-end my-2">
                <div className="col flex-column">
                    <SummaryCard summaryData={groupedTicketDetails} />
                </div>
                <div className="col flex-column">
                    {/* <ProducerDetailsCard producer={selectedProducer} /> */}
                </div>

                <div className="col align-items-end flex-column">
                    <div className="d-flex justify-content-end">
                        <input
                            disabled={isDisabled}
                            type="text"
                            className="form-control"
                            value={newPrice}
                            pattern="^\d+(\.\d{0,3})?$"
                            onChange={(e) => {
                                if (e.target.validity.valid) {
                                    setNewPrice(e.target.value);
                                }
                            }}
                            style={{ maxWidth: '150px' }}
                        />
                        <Button variant="contained"
                            disabled={isDisabled}
                            className="ms-2"
                            onClick={applyPriceToAll}
                        >
                            Apply Price
                        </Button>
                    </div>
                </div>
            </div>

            <div>
                <DataGrid
                    style={{ height: 300 }}
                    apiRef={apiRef}
                    checkboxSelection={!isDisabled}
                    rows={ticket.ticketDetails}
                    columns={columns}
                    loading={loading}
                    getRowId={(row) => row.ticketDetailId}
                    rowHeight={30} // Decrease the row height
                />
            </div>

        </Box>
    );
}

export default Ticket;

