import { Link } from "react-router-dom";
import React, { useEffect } from 'react';
import { useBreadcrumb } from '../breadcrumb-context';

export default function Index() {

  const { setBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbs([]);

    // Clean up function
    return () => {
      setBreadcrumbs([]);
    };
  }, [setBreadcrumbs]);

  return (

    <div class="container-fluid" style={{ height: '100%' }}>
      <div class="row justify-content-start">
        <div class="col-sm-auto">
          <div className="tile green">
            <Link to={`tickets`}>
              <img src={`${import.meta.env.VITE_VIRTUAL_DIRECTORY}/ticket-64.png`}></img>
              <h1>Tickets</h1>
            </Link>
          </div>
        </div>
        <div class="col-sm-auto">
          <div className="tile blue">

            <Link to={`settings`}>
              <img src={`${import.meta.env.VITE_VIRTUAL_DIRECTORY}/gear-2-64.png`}></img>
              <h1>Settings</h1>
            </Link>

          </div>
        </div>
      </div>
    </div>


  );
}