// authConfig.js

export const msalConfig = {
    auth: {
      clientId: "cfea6e05-edf0-41f1-8060-2099f50270f2",  // This is the ID of the Azure AD app registration
      authority: "https://login.microsoftonline.com/aba8cd75-988b-44bc-adf8-b38c5d48c904", // This is your Azure AD tenant ID
      redirectUri: import.meta.env.VITE_REDIRECT_URL,  // or your SPA's URI
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  };
  
  export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};