import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useBreadcrumb } from './breadcrumb-context';

function Breadcrumb() {

    const { breadcrumbs } = useBreadcrumb();

    return (
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink to="/" color="inherit">
          Egg Collection
        </NavLink>
        {breadcrumbs.map(({ label, path }, index) => {
          const last = index === breadcrumbs.length - 1;
  
          return last ? (
            <Typography color="text.primary" key={path}>
              {label}
            </Typography>
          ) : (
            <Link component={NavLink} to={path} color="inherit" key={path}>
              {label}
            </Link>
          );
        })}
      </Breadcrumbs>
    );

}

export default Breadcrumb;