import React, { useState, useEffect, useRef } from 'react';
import { useBreadcrumb } from '../../breadcrumb-context';
import DataService from "../../data-service";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ErrorBlock from '../../error-block';
import { Skeleton, Box, Button, LinearProgress } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { toast } from 'react-toastify';
import { useMsal } from "@azure/msal-react";

export default function Producers() {

    const { setBreadcrumbs } = useBreadcrumb();
    const [producers, setProducers] = useState([]);
    const [syncing, setSyncing] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);

    const { instance, accounts } = useMsal();
    const dataService = new DataService(instance, accounts[0]);

    useEffect(() => {
        getProducers();
        setBreadcrumbs([
            { label: 'Settings', path: '/settings' },
            { label: 'Producers', path: '/settings/producers' },
        ]);
    }, []); // Empty dependency array, so the effect only runs on mount and unmount);

    async function getProducers() {
        const producerData = await dataService.getAll('producer');
        const sortedProducerData = producerData.sort((a, b) => a.producerName.localeCompare(b.producerName));
        setProducers(sortedProducerData);
    }

    const clearError = () => {
        setErrorMessages(null);
    };

    const columns = [
        { field: 'producerName', headerName: 'Producer Name', width: 200, flex:1 },
        { field: 'croppProducerId', headerName: 'Producer Id', width: 125 },
        { field: 'barnNumber', headerName: 'Barn #', width: 125 },
        { field: 'croppPatronId', headerName: 'Patron Id', width: 125 },
        { field: 'croppFarmId', headerName: 'Farm Id', width: 125 },
        { field: 'erpProductId', headerName: 'ERP Product Id', width: 125 },
        { field: 'erpProducerId', headerName: 'ERP Producer Id', width: 125 },
        { field: 'erpPatronId', headerName: 'ERP Patron Id', width: 125 },
        { field: 'erpFarmId', headerName: 'ERP Farm Id', width: 125 },
    ];

    const handleSync = async (event) => {
        event.preventDefault();
        setSyncing(true);
        try {
            const response = await dataService.syncProducers();
    
            if (!response.isSuccess) {
                setErrorMessages(response);
            } else {
                await getProducers();
                setErrorMessages(null);
                toast.success('Producers synced.', { autoClose: 2000 });
            }
        } catch (error) {
            setErrorMessages(error);
        } finally {
            setSyncing(false);
        }
    };
    

    return (
        <Box>
            {
                syncing && (
                    <Box mb={2}>
                        <LinearProgress />
                    </Box>
                )
            }

            <ErrorBlock error={errorMessages} onClearError={clearError} />
            <Box mb={2} display="flex" justifyContent="flex-end">
                <Button disabled={syncing} variant='contained' onClick={handleSync} endIcon={<SyncIcon />} >
                    Sync
                </Button>
            </Box>
            <Box>
                {producers.length ? (
                    <DataGrid
                        columns={columns}
                        rows={producers}
                        getRowId={(row) => row.producerId}
                        style={{ height: 450 }}
                        initialState={{
                            sorting: {
                              sortModel: [{ field: 'producerName', sort: 'asc' }],
                            },
                          }}
                        rowHeight={30} // Decrease the row height
                        slots={{ toolbar: GridToolbar }}
                    />
                ) : (
                    <Skeleton />
                )}

            </Box>
        </Box>


    );
}

