import React, { useState } from 'react';
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Chip, AppBar, Box, Container, Button, Modal } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Breadcrumb from "../breadcrumb";
import 'react-toastify/dist/ReactToastify.css';
import { useIsAuthenticated, useMsal  } from "@azure/msal-react";
import DataService from "../data-service";

export default function Root() {

    const [environment, setEnvironment] = useState();
    const [open, setOpen] = useState(false);
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const dataService = new DataService(instance, accounts[0]);

    const handleLogin = async () => {
        try {
            const response = await instance.loginPopup();
            instance.setActiveAccount(response.account);
            await getEnvironment();
        } catch (error) {
            console.error('An error occurred during login:', error);
        }
    };
    

    const handleLogout = () => {
        const account = accounts[0];
        instance.logout({ account });
    };

    const showEnvironment = async () => {
        setOpen(true);
        if (!environment) {
            await getEnvironment();
        }
    };
    
    async function getEnvironment() {
        const env = await dataService.getAll('environment');
        setEnvironment(env);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const modalBody = (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px' }}>
          <h2>Environment Details</h2>
          <p>{`Environment: ${import.meta.env.VITE_ENVIRONMENT}`}</p>
          {environment ? (
            <>
                <p>{`SAP API: ${environment.sapBaseUrl}`}</p>
                <p>{`CROPP API: ${import.meta.env.VITE_API_URL}`}</p>
                <p>{`Database: ${environment.database}`}</p>
            </>
          ) : (
            <p>Loading environment details...</p>
          )
          }

          <Button onClick={handleClose}>Close</Button>
        </div>
      );

    const footerStyle = {
        backgroundImage: `url(${import.meta.env.VITE_VIRTUAL_DIRECTORY}/footer-mask.svg)`,
        height: '164px', // Adjust this value as needed
        position: 'fixed',
        bottom: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const chipStyle = {
        cursor: 'pointer', // Makes cursor a pointer on hover
        '&:hover': {
          backgroundColor: '#f0f0f0', // Changes background color on hover
        },
      };


    return (
        <>
            <Box>
                <AppBar position="static" color='default'>
                    <div className="d-flex align-items-center">
                        <div>
                            <img
                                src={`${import.meta.env.VITE_VIRTUAL_DIRECTORY}/app-logo.png`}
                                style={{ height: "50px", width: "auto" }}
                                alt="OV Logo"
                            />
                        </div>
                        <div className="ms-2 flex-grow-1">
                            <Breadcrumb />
                        </div>
                        <Chip onClick={showEnvironment} className="me-2" color="success" variant="outlined" label={`Environment: ${import.meta.env.VITE_ENVIRONMENT}`} icon={<InfoIcon />} sx={chipStyle} />
                        <Modal open={open} onClose={handleClose}>
                            {modalBody}
                        </Modal>
                        <Chip color="success" variant="outlined" label={`Version: ${import.meta.env.VITE_VERSION}`} />
                        {isAuthenticated ? 
                            <>
                                <Chip className="ms-2 me-2" color="success" variant="outlined" label={`User: ${accounts[0]?.name}`} />
                                <Button onClick={handleLogout}>Log out</Button>
                            </> : 
                            <Button onClick={handleLogin}>Log in</Button>
                        }
                    </div>
                </AppBar>
            </Box>
            <Container maxWidth="xl">
                <ToastContainer />
                <Box mt={2}>
                    <Outlet />
                </Box>
            </Container>
            <div style={footerStyle}>
            </div>
        </>
    );
}

