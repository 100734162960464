import { createClaimsTable } from '../utils/claim-utils';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box
  } from '@mui/material';
import React, { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import DataService from "../data-service";

export const IdTokenData = (props) => {

    console.log("component mounted.");

    const { instance, accounts } = useMsal();
    const dataService = new DataService(instance, accounts[0]);

    const tokenClaims = createClaimsTable(props.idTokenClaims);

    const tableRow = Object.keys(tokenClaims).map((key, index) => {
        return (
            <tr key={key}>
                {tokenClaims[key].map((claimItem) => (
                    <td key={claimItem}>{claimItem}</td>
                ))}
            </tr>
        );
    });


    useEffect(() => {
        console.log("getProducers called");
        getProducers();
    }, []); // Empty dependency array, so the effect only runs on mount and unmount);

    async function getProducers() {
        const producerData = await dataService.getAll('producer');
        console.log(producerData);
    }


    return (
        <>
            <Box>
                <p>
                    See below the claims in your <strong> ID token </strong>. For more information, visit:{' '}
                    <span>
                        <a href="https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token">
                            docs.microsoft.com
                        </a>
                    </span>
                </p>
            </Box>
            <Box>
                <Table>
                    <thead>
                        <tr>
                            <th>Claim</th>
                            <th>Value</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>{tableRow}</tbody>
                </Table>
            </Box>



        </>
    );
};