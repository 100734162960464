import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Index from "./routes/index";
import Producers from "./routes/producers/list";
import Settings from "./routes/settings/index";
import Tickets from "./routes/tickets/list";
import Ticket from "./routes/tickets/ticket";
import { BreadcrumbProvider } from './breadcrumb-context';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider, useMsal, AuthenticatedTemplate, useIsAuthenticated  } from '@azure/msal-react'; // Make sure to import MsalProvider
import { msalConfig, loginRequest } from './authConfig';
import { IdTokenData } from "./components/my-claims";

/* The code above does the following, explained in English:
1. Check if there are any accounts signed in
2. If there is an account signed in, get the user roles
3. If there are no accounts signed in, initiate login process
4. If there is an account signed in, get the user roles
5. If there are no user roles, get the user roles
6. If there are user roles, use them to make an API call
7. If there are no user roles, log an error
8. If the API call fails, log an error
9. If the API call succeeds, use the data */

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

function App() {
  const { instance, accounts } = useMsal();
  const [idTokenClaims, setIdTokenClaims] = useState(null);

  useEffect(() => {
    if (accounts.length === 0) {
      // if there's no account, initiate login process
      instance.loginPopup(loginRequest).then((response) => {
        instance.setActiveAccount(response.account);
      }).catch((error) => {
        console.error(error);
      });
    }
    else {
      const activeAccount = instance.getActiveAccount();
      if (activeAccount) {
        setIdTokenClaims(activeAccount.idTokenClaims);

        // get user roles
        const request = {
          scopes: ["api://cropp.eggcollection.api/.default"],
          account: activeAccount
        };
  
        instance.acquireTokenSilent(request).then(response => {

          console.log(response);
          //setUserRoles(response.idTokenClaims.roles || []);
        }).catch(error => {
          console.error(error);
        });

      }
    }
  }, [instance, accounts]);



  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            { index: true, 
              element: <AuthenticatedTemplate><Index /> </AuthenticatedTemplate>
            },
            {
              path: "/claims",
              element:
                <AuthenticatedTemplate>
                  <IdTokenData idTokenClaims={idTokenClaims} />
                </AuthenticatedTemplate>,
            },
            {
              path: "/settings/producers",
              element: <Producers />,
            },
            {
              path: "/settings",
              element: <Settings />,
            },
            {
              path: "/tickets",
              element: (
                <AuthenticatedTemplate>
                  <Tickets />
                </AuthenticatedTemplate>
              ),
              errorElement: <ErrorPage />,
            },
            {
              path: "/tickets/:ticketId",
              element: <Ticket />,
            },
          ]
        }
      ]
    },
  ], {
    basename: import.meta.env.VITE_VIRTUAL_DIRECTORY || '/',
  });

  return (
    <BreadcrumbProvider>
      <RouterProvider router={router} />
    </BreadcrumbProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}> {/* Wrap your application with MsalProvider */}
      <App />
    </MsalProvider>
  </React.StrictMode>
);
