import React, { useState } from 'react';
import { Alert, Box } from '@mui/material';

const ErrorBlock = ({ error, onClearError }) => {
    if (!error) {
        return null;
    }

    const [showDetails, setShowDetails] = useState(false);

    return (
        <Box mb={2}>
            {error && (
                <Alert onClose={onClearError} severity="error">
                    {error.message && error.message.split('\r\n').map((line, i) => (
                        <div key={i}>
                            {line}
                            <br />
                        </div>
                    ))}
                    {
                        error.FieldErrors && (
                            <ul>
                                {errorMessages.FieldErrors.map((fieldError, index) => (
                                    <li key={index}>
                                        {fieldError.FieldName}: {fieldError.ErrorMessage}
                                    </li>
                                ))}
                            </ul>
                        )
                    }
                    {
                        error.unexpectedErrorMessage && (
                            <div>
                                {error.unexpectedErrorMessage}
                            </div>
                        )
                    }

                    <div>
                        <a href="#" onClick={() => setShowDetails(!showDetails)}>
                            {showDetails ? 'Hide Details' : 'Show Details'}
                        </a>
                    </div>
                    {
                        showDetails && (
                            <div>
                                <div>
                                    {error.response?.data}
                                </div>
                                {
                                    error.submittedPayload && (
                                        <div>
                                            <h6>Payload</h6>
                                            {error.submittedPayload}
                                        </div>
                                    )
                                }
                                {
                                    error.apiResponse && (
                                        <div>
                                            <h6>Response</h6>
                                            {error.apiResponse}
                                        </div>
                                    )
                                }
                            </div>


                        )
                    }



                </Alert>
            )}

        </Box>
    );

};

export default ErrorBlock;
