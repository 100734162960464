import { Link } from "react-router-dom";

export default function Settings() {
    return (
      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-sm-auto">
            <div className="tile pink">
            <Link to={`producers`}>
              <h3>Producers</h3>
            </Link>
            </div>
          </div>  
        </div>
      </div>
    );
  }